/* Format and styling based on: https://w.amazon.com/bin/view/Shortbread/documentation/ux#HLEGALZONE */
.footer {
  width: auto;
  display: flex;
  background-color: #232f3e;
  align-items: center;
  padding: 1.5em 1em;
  box-sizing: border-box;
  flex-wrap: wrap;
  min-height: 95px;
  justify-content: center;
}

.footer__links {
  display: flex;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}

#footer__links_preferences {
  cursor: pointer;
}

.footer__divider {
  color: #879596;
  font-size: 12px;
  padding: 0 5px 0 5px;
}

.footer__element {
  color: #44b9d6;
  text-decoration: none;
}

.footer__copyright {
  color: #95a5a6;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}
